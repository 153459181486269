import * as React from "react";
import { ReactNode, useState, useMemo, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthUser, useSignOut } from "react-auth-kit";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import ViewInArIcon from "@mui/icons-material/ViewInAr";
import TerminalIcon from "@mui/icons-material/Terminal";
import AlbumIcon from "@mui/icons-material/Album";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import KeyIcon from "@mui/icons-material/Key";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";
import StorageIcon from "@mui/icons-material/Storage";
import EditNoteIcon from "@mui/icons-material/EditNote";
import WebStoriesIcon from "@mui/icons-material/WebStories";
import BlockIcon from "@mui/icons-material/Block";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { revoke } from "../services/aws/CognitoService";
import Routes from "./routing/Routes";
import { AppContext, TargetAPI } from "utils/AppContext";
import { Collapse, Tooltip } from "@mui/material";
import { EnvironmentSelector } from "./EnvironmentSelector";
import { IEnvironment } from "../services/registry/EnvironmentService";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import { SettingsSuggest } from "@mui/icons-material";
import BugReportIcon from "@mui/icons-material/BugReport";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { isRunningOnProd } from "../utils/Environment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ApiIcon from "@mui/icons-material/Api";
import BackupIcon from "@mui/icons-material/Backup";
import WbCloudyIcon from "@mui/icons-material/WbCloudy";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import FilterIcon from "@mui/icons-material/Filter";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ListAltIcon from "@mui/icons-material/ListAlt";

interface DrawerButton {
  label: string;
  icon: ReactNode;
  onClick?: () => void;
  route?: string;
  nested?: DrawerButton[];
}
type DrawerItem = DrawerButton | "divider";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface AppFrameInternalProps {
  drawerWidth: number;
  drawerItems: DrawerItem[];
}

interface ItemProps {
  button: DrawerButton;
  openedDrawer: boolean;
}

function ListItemWithCollapse({ button, openedDrawer }: ItemProps) {
  const { pathname } = useLocation();
  const [openCollapse, setOpenCollapse] = useState(false);
  const handleClick = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <React.Fragment>
      <ListItem key={button.label} disablePadding sx={{ display: "block" }}>
        <Tooltip title={button.label} placement="right">
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: openCollapse ? "initial" : "center",
              px: 2.5,
            }}
            onClick={button.nested ? handleClick : button.onClick}
            selected={button.route === pathname}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: openedDrawer ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              {button.icon}
            </ListItemIcon>
            <ListItemText primary={button.label} sx={{ opacity: openedDrawer ? 1 : 0 }} />
            {button.nested ? openCollapse ? <ExpandLess /> : <ExpandMore /> : <></>}
          </ListItemButton>
        </Tooltip>
      </ListItem>
      {button.nested && (
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {button.nested.map((item, index) => {
              return (
                <ListItemDefault key={index} button={item as DrawerButton} openedDrawer={openedDrawer}></ListItemDefault>
              );
            })}
          </List>
        </Collapse>
      )}
    </React.Fragment>
  );
}

function ListItemDefault({ button, openedDrawer }: ItemProps) {
  const { pathname } = useLocation();
  return (
    <React.Fragment>
      <ListItem key={button.label} disablePadding sx={{ display: "block" }}>
        <Tooltip title={button.label} placement="right">
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: "center",
              px: 2.5,
              pl: 4,
            }}
            onClick={button.onClick}
            selected={button.route === pathname}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: 3,
                justifyContent: "center",
              }}
            >
              {button.icon}
            </ListItemIcon>
            <ListItemText primary={button.label} sx={{ opacity: openedDrawer ? 1 : 0 }} />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </React.Fragment>
  );
}

const AppFrameInternal = ({ drawerWidth, drawerItems }: AppFrameInternalProps) => {
  const theme = useTheme();
  const [open, setOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [targetAPI, setTargetAPI] = useState<TargetAPI>("registry");
  const [selectedEnvironment, setSelectedEnvironment] = useState<IEnvironment | undefined>(undefined);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const { DrawerHeader, AppBar, Drawer } = useMemo(() => {
    const openedMixin = (theme: Theme): CSSObject => ({
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      overflowX: "hidden",
    });

    const closedMixin = (theme: Theme): CSSObject => ({
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: `calc(${theme.spacing(7)} + 1px)`,
      [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
    });

    const DrawerHeader = styled("div")(({ theme }) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }));

    const AppBar = styled(MuiAppBar, {
      shouldForwardProp: (prop) => prop !== "open",
    })<AppBarProps>(({ theme, open }) => ({
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      }),
    }));

    const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
    }));

    return { DrawerHeader, AppBar, Drawer };
  }, [drawerWidth]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Townsfolk Admin Panel - {title}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {targetAPI === "environment" && (
            <EnvironmentSelector
              id="app-frame"
              selectedEnvironment={selectedEnvironment}
              onEnvironmentSelected={setSelectedEnvironment}
              useStorage={true}
            />
          )}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerItems.map((item, index) => {
            if (item === "divider") {
              return <Divider key={index} />;
            } else {
              {
                const button = item as DrawerButton;
                return <ListItemWithCollapse key={index} button={button} openedDrawer={open}></ListItemWithCollapse>;
              }
            }
          })}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <AppContext.Provider
          value={{ title, setTitle, targetAPI, setTargetAPI, selectedEnvironment, setSelectedEnvironment }}
        >
          <Outlet />
        </AppContext.Provider>
      </Box>
    </Box>
  );
};

export const AppFrame = () => {
  const navigate = useNavigate();
  const signOut = useSignOut();
  const authUser = useAuthUser();

  const logout = async () => {
    sessionStorage.removeItem("lastSuccessfulLoginTime");
    const refreshToken = authUser()?.refresh_token || "";
    await revoke(refreshToken);
    signOut();
    navigate(Routes.landing);
  };

  const featureFlagsDrawerItem: DrawerItem = {
    label: "Feature Flags & Segmentation",
    icon: <SettingsSuggest />,
    onClick: () => window.open("https://app.growthbook.io/features/"),
  };

  const contentModerationDrawerItem: DrawerItem = {
    label: "Content Moderation & Users Reporting",
    icon: <BlockIcon />,
    onClick: () => window.open("https://dashboard.intrinsicapi.com/auth/"),
  };

  const apiDocumentationDrawerItem: DrawerItem = {
    label: "Backend API Documentation",
    icon: <LibraryBooksIcon />,
    onClick: () => navigate(Routes.swaggerDoc),
    route: Routes.swaggerDoc,
  };

  let drawerItems: DrawerItem[];
  if (isRunningOnProd()) {
    drawerItems = [
      { label: "Home", icon: <HomeIcon />, onClick: () => navigate(Routes.home), route: Routes.home },
      "divider",
      {
        label: "Environments",
        icon: <WorkspacesIcon />,
        onClick: () => navigate(Routes.environments),
        route: Routes.environments,
      },
      {
        label: "Managed Parameters",
        icon: <KeyIcon />,
        onClick: () => navigate(Routes.managedParameters),
        route: Routes.managedParameters,
      },
      "divider",
      featureFlagsDrawerItem,
      contentModerationDrawerItem,
      apiDocumentationDrawerItem,
      "divider",
      {
        label: "Data Entity Editor",
        icon: <AutoFixHighIcon />,
        onClick: () => navigate(Routes.dataEntityEditor),
        route: Routes.dataEntityEditor,
      },
      {
        label: "Players",
        icon: <PeopleAltIcon />,
        onClick: () => navigate(Routes.players),
        route: Routes.players,
      },
      {
        label: "Game Server Actions",
        icon: <PendingActionsIcon />,
        onClick: () => navigate(Routes.gameServerActions),
        route: Routes.gameServerActions,
      },
      "divider",
      { label: "Profile", icon: <PersonIcon />, onClick: () => navigate(Routes.profile), route: Routes.profile },
      "divider",
      { label: "Sign-out", icon: <LogoutIcon />, onClick: () => logout() },
    ];
  } else {
    drawerItems = [
      { label: "Home", icon: <HomeIcon />, onClick: () => navigate(Routes.home), route: Routes.home },
      "divider",
      {
        label: "Environments",
        icon: <WorkspacesIcon />,
        onClick: () => navigate(Routes.environments),
        route: Routes.environments,
      },
      {
        label: "Wads & Packages",
        icon: <ViewInArIcon />,
        onClick: () => navigate(Routes.staticData),
        route: Routes.staticData,
      },
      {
        label: "Client Builds",
        icon: <TerminalIcon />,
        onClick: () => navigate(Routes.clientBuilds),
        route: Routes.clientBuilds,
      },
      {
        label: "Server Images",
        icon: <StorageIcon />,
        onClick: () => navigate(Routes.serverImages),
        route: Routes.serverImages,
      },
      {
        label: "Backend Images",
        icon: <AlbumIcon />,
        onClick: () => navigate(Routes.backendImages),
        route: Routes.backendImages,
      },
      {
        label: "Managed Parameters",
        icon: <KeyIcon />,
        onClick: () => navigate(Routes.managedParameters),
        route: Routes.managedParameters,
      },
      "divider",
      featureFlagsDrawerItem,
      contentModerationDrawerItem,
      apiDocumentationDrawerItem,
      "divider",
      {
        label: "Data Schema Editor",
        icon: <EditNoteIcon />,
        onClick: () => navigate(Routes.dataSchemaEditor),
        route: Routes.dataSchemaEditor,
      },
      {
        label: "Data Entity Editor",
        icon: <AutoFixHighIcon />,
        onClick: () => navigate(Routes.dataEntityEditor),
        route: Routes.dataEntityEditor,
      },
      {
        label: "Players",
        icon: <PeopleAltIcon />,
        onClick: () => navigate(Routes.players),
        route: Routes.players,
      },
      "divider",
      {
        label: "Game Servers",
        icon: <StorageIcon />,
        onClick: () => navigate(Routes.gameServers),
        route: Routes.gameServers,
      },
      {
        label: "Game Server Actions",
        icon: <PendingActionsIcon />,
        onClick: () => navigate(Routes.gameServerActions),
        route: Routes.gameServerActions,
      },
      "divider",
      {
        label: "Load Testers",
        icon: <FitnessCenterIcon />,
        onClick: () => navigate(Routes.loadTesters),
        route: Routes.loadTesters,
      },
      {
        label: "Logging",
        icon: <WebStoriesIcon />,
        nested: [
          {
            label: "Playfab Game Server",
            icon: <SportsEsportsIcon />,
            onClick: () => navigate(Routes.logging),
            route: Routes.logging,
          },
          {
            label: "Backend Logs",
            icon: <ApiIcon />,
            onClick: () => navigate(Routes.cwLogging),
            route: Routes.cwLogging,
          },
          {
            label: "Integration Tests",
            icon: <BugReportIcon />,
            onClick: () => navigate(Routes.integrationTests),
            route: Routes.integrationTests,
          },
        ],
      },
      {
        label: "Art Assets",
        icon: <FilterIcon />,
        nested: [
          {
            label: "Create New Art Assets IDs",
            icon: <AddCircleIcon />,
            onClick: () => navigate(Routes.artAssetsIdsGenerator),
            route: Routes.artAssetsIdsGenerator,
          },
          {
            label: "View Art Assets IDs",
            icon: <AssignmentIcon />,
            onClick: () => navigate(Routes.artAssetsIdsBrowser),
            route: Routes.artAssetsIdsBrowser,
          },
          {
            label: "View Raw Sheet",
            icon: <ListAltIcon />,
            onClick: () => navigate(Routes.artAssetsIdsRawSheet),
            route: Routes.artAssetsIdsRawSheet,
          },
        ],
      },
      "divider",
      {
        label: "Backups",
        icon: <BackupIcon />,
        nested: [
          {
            label: "Full",
            icon: <WbCloudyIcon />,
            onClick: () => navigate(Routes.backupFull),
            route: Routes.backupFull,
          },
          {
            label: "Partial",
            icon: <CloudQueueIcon />,
            onClick: () => navigate(Routes.backupPartial),
            route: Routes.backupPartial,
          },
        ],
      },
      "divider",
      { label: "Profile", icon: <PersonIcon />, onClick: () => navigate(Routes.profile), route: Routes.profile },
      "divider",
      { label: "Sign-out", icon: <LogoutIcon />, onClick: () => logout() },
    ];
  }

  return <AppFrameInternal drawerWidth={370} drawerItems={drawerItems} />;
};
