import * as React from "react";
import {
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Grid,
  TextField,
  Box,
  Paper,
  Collapse,
  IconButton,
  Checkbox,
  AlertColor,
} from "@mui/material";
import { ChangeEvent, useMemo, useState } from "react";
import { getComparator, stableSort } from "utils/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IPartialBackup, useBackupService } from "services/backend/BackupService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DateTime } from "luxon";
import JsonViewer from "components/JSONViewer";
import { LoadingOverlay } from "components/LoadingOverlay";
import DifferenceIcon from "@mui/icons-material/Difference";
import DeleteIcon from "@mui/icons-material/Delete";
import JSONDiff, { IDiffData } from "components/JSONDiff";
import RestoreIcon from "@mui/icons-material/Restore";
import { YesNoPopup } from "components/YesNoPopup";
import { Notify } from "components/Notify";
import { useDataPersistenceService } from "services/backend/DataService";

export interface ITableProps {
  rows: IPartialBackup[];
}

interface ISelectedItem {
  key: string;
  entityId: string;
  file: string;
  type: string;
}

interface IRowProps {
  item: IPartialBackup;
  setSelectedItems: (data: ISelectedItem[]) => void;
  selectedItems: ISelectedItem[];
}
function Row(prop: IRowProps) {
  const { item, selectedItems, setSelectedItems } = prop;
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const backupService = useBackupService();
  const [jsonViewerVisible, setJsonViewerVisible] = useState<boolean>(false);
  const [jsonData, setJsonData] = useState<string>("{}");
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<IPartialBackup | undefined>(undefined);
  const [selectedFile, setSelectedFile] = useState<string>("");
  const [restoreConfirmationPopupVisible, setRestoreConfirmationPopupVisible] = useState<boolean>(false);
  const [deleteConfirmationPopupVisible, setDeleteConfirmationPopupVisible] = useState<boolean>(false);

  const isSelected = (entityId: string, file: string) =>
    selectedItems.findIndex((f) => f.file === file && f.entityId === entityId) > -1;

  const toggleCollapse = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const loadPartialContent = async (path: string) => {
    try {
      setLoading(true);
      const content = await backupService.loadPartialBackup(path);
      setJsonData(JSON.stringify(content));
      setJsonViewerVisible(true);
    } catch (error) {
      setAlertMessage("Could not load the partial backup content.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const restorePartialBackup = async () => {
    if (selectedFile && selectedItem) {
      setRestoreConfirmationPopupVisible(false);
      const path = `${selectedItem.key}/${selectedFile}`;
      try {
        setLoading(true);
        await backupService.restorePartialBackup(selectedItem.type, path);
        setAlertMessage("Restoration completed.");
        setAlertSeverity("success");
      } catch (error) {
        console.log(error);
        setAlertMessage("Could not restore the partial backup.");
        setAlertSeverity("error");
      } finally {
        setLoading(false);
      }
    }
  };

  const deletePartialBackup = async () => {
    if (selectedFile && selectedItem) {
      setDeleteConfirmationPopupVisible(false);
      const path = `${selectedItem.key}/${selectedFile}`;
      try {
        setLoading(true);
        await backupService.deletePartialBackup(selectedItem.type, path);
        item.files = item.files.filter((it) => it != selectedFile);
        setSelectedItems(selectedItems.filter((it) => it.file !== selectedFile));
        setAlertMessage("Delete completed.");
        setAlertSeverity("success");
      } catch (error) {
        console.log(error);
        setAlertMessage("Could not delete the partial backup.");
        setAlertSeverity("error");
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRestoreClick = (event: React.MouseEvent<unknown>, item: IPartialBackup, file: string) => {
    event.stopPropagation();
    setSelectedFile(file);
    setSelectedItem(item);
    setRestoreConfirmationPopupVisible(true);
  };

  const handleDeleteClick = (event: React.MouseEvent<unknown>, item: IPartialBackup, file: string) => {
    event.stopPropagation();
    setSelectedFile(file);
    setSelectedItem(item);
    setDeleteConfirmationPopupVisible(true);
  };

  const selectionChangeHandler = (event: React.MouseEvent<unknown>, selected: ISelectedItem): void => {
    const selectedIndex = selectedItems.findIndex((f) => f.file === selected.file);
    let newSelected: ISelectedItem[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedItems, selected);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
      newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }
    setSelectedItems(newSelected);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell width={15}>
          <IconButton aria-label="expand row" size="small" onClick={toggleCollapse}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{item.date}</TableCell>
        <TableCell>{item.type}</TableCell>
        <TableCell>{item.entityId}</TableCell>
        <TableCell>{item.schemaVersion}</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>File</TableCell>
                    <TableCell colSpan={4}>Creation Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {item.files.map((file) => {
                    const creationDate = DateTime.fromMillis(+file.split(".")[0]).toLocaleString(
                      DateTime.DATETIME_SHORT_WITH_SECONDS,
                    );

                    return (
                      <TableRow
                        hover
                        key={file}
                        onClick={(event) =>
                          selectionChangeHandler(event, { key: item.key, entityId: item.entityId, file, type: item.type })
                        }
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox color="primary" checked={isSelected(item.entityId, file)} />
                        </TableCell>
                        <TableCell>{file}</TableCell>
                        <TableCell colSpan={3}>{creationDate}</TableCell>
                        <TableCell align="right">
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<VisibilityIcon />}
                            onClick={(e) => {
                              e.stopPropagation();
                              loadPartialContent(`${item.key}/${file}`);
                            }}
                          >
                            Show
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<RestoreIcon />}
                            onClick={(event) => handleRestoreClick(event, item, file)}
                          >
                            Restore
                          </Button>
                          <Button
                            size="small"
                            variant="outlined"
                            startIcon={<DeleteIcon />}
                            onClick={(event) => handleDeleteClick(event, item, file)}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <JsonViewer
        visible={jsonViewerVisible}
        data={JSON.stringify(JSON.parse(jsonData), null, 2)}
        onClose={() => setJsonViewerVisible(false)}
      ></JsonViewer>
      <YesNoPopup
        visible={restoreConfirmationPopupVisible}
        title={"Restore partial backup"}
        message={`Are you sure you want to restore this partial backup?`}
        onNo={() => setRestoreConfirmationPopupVisible(false)}
        onYes={restorePartialBackup}
      />
      <YesNoPopup
        visible={deleteConfirmationPopupVisible}
        title={"Delete partial backup"}
        message={`Are you sure you want to delete this partial backup?`}
        onNo={() => setDeleteConfirmationPopupVisible(false)}
        onYes={deletePartialBackup}
      />
      {loading && <LoadingOverlay />}
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </React.Fragment>
  );
}

export default function PartialBackupTable({ rows }: ITableProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableFilter, setTableFilter] = useState<string>("");
  const [selectedItems, setSelectedItems] = useState<ISelectedItem[]>([]);
  const backupService = useBackupService();
  const dataService = useDataPersistenceService();
  const [jsonDiffVisible, setJsonDiffVisible] = useState<boolean>(false);
  const [jsonDiffData1, setJsonDiffData1] = useState<IDiffData>({ title: "", content: {} });
  const [jsonDiffData2, setJsonDiffData2] = useState<IDiffData>({ title: "", content: {} });
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [loading, setLoading] = useState(false);

  const canCompare = () => {
    if (selectedItems.length > 2 || selectedItems.length === 0) {
      return false;
    }
    if (selectedItems.length === 2 && selectedItems[0].entityId != selectedItems[1].entityId) {
      return false;
    }

    return true;
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = useMemo(() => {
    setPage(0);
    return rows.filter((row) => {
      return row.entityId.toLowerCase().includes(tableFilter.toLowerCase());
    });
  }, [rows, tableFilter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator("desc", "date")).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, page, rowsPerPage]);

  const handleCompareClick = async () => {
    try {
      setLoading(true);
      selectedItems.sort();
      if (selectedItems.length == 1) {
        const file1 = selectedItems[0];
        const liveContent = await dataService.loadDataEntity(file1.type, file1.entityId);
        const file1Content = await backupService.loadPartialBackup(`${file1.key}/${file1.file}`);

        setJsonDiffData1({ title: file1.file, content: file1Content } as IDiffData);
        setJsonDiffData2({ title: "live", content: liveContent } as IDiffData);
      } else {
        const file1 = selectedItems[0];
        const file2 = selectedItems[1];
        if (file1.key !== file2.key) {
          return;
        }
        const file1Content = await backupService.loadPartialBackup(`${file1.key}/${file1.file}`);
        const file2Content = await backupService.loadPartialBackup(`${file2.key}/${file2.file}`);
        setJsonDiffData1({ title: file1.file, content: file1Content } as IDiffData);
        setJsonDiffData2({ title: file2.file, content: file2Content } as IDiffData);
      }

      setJsonDiffVisible(true);
    } catch (error) {
      console.log(error);
      setAlertMessage("Could not compare the partial backups.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ margin: 1 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ pl: { sm: 1 }, pr: { xs: 1, sm: 1 }, pt: { sm: 1 }, pb: { sm: 1 } }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-search"
                label="Search partial backups"
                type="search"
                onChange={(e) => setTableFilter(e.target.value)}
                sx={{ width: "360px" }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Date</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Entity Id</TableCell>
                <TableCell>Schema Version</TableCell>
                <TableCell width={280} align="center">
                  <Button
                    disabled={!canCompare()}
                    size="small"
                    variant="outlined"
                    startIcon={<DifferenceIcon />}
                    onClick={() => handleCompareClick()}
                  >
                    {selectedItems.length === 1 ? `Compare with Live` : `Compare`}
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleRows &&
                visibleRows.map((item: IPartialBackup) => (
                  <Row key={item.key} item={item} setSelectedItems={setSelectedItems} selectedItems={selectedItems ?? []} />
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
      <JSONDiff
        visible={jsonDiffVisible}
        oldData={jsonDiffData1}
        newData={jsonDiffData2}
        onClose={() => setJsonDiffVisible(false)}
      ></JSONDiff>
      {loading && <LoadingOverlay />}
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
    </Box>
  );
}
