import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Routes from "../../components/routing/Routes";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import { Background } from "../../components/Background";
import { Notify } from "../../components/Notify";
import { useIsAuthenticated } from "../../services/auth/AuthService";
import { AppView } from "components/AppView";

const AdminPanelText = styled.div`
  font-family: "Helvetica Neue";
  font-weight: bold;
  font-size: xxx-large;
  color: white;
  text-shadow: #000 0px 0px 12px;
  margin-bottom: 24px;
  user-select: none;
`;

const LandingPage = () => {
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  const errorMessage = location.state?.errorMessage;
  let content;

  const signInButtonClickHandler = () => {
    sessionStorage.setItem("requestedRoute", window.location.pathname + (window.location.search || ""));
    navigate(Routes.login);
  };

  if (!isAuthenticated) {
    const lastAutoLoginAttemptTime = parseInt(sessionStorage.getItem("lastAutoLoginAttemptTime") || "0");
    const lastSuccessfulLoginTime = parseInt(sessionStorage.getItem("lastSuccessfulLoginTime") || "0");
    // If we had a successful login in the past 4 hours and our last attempt was at least 5 seconds ago, then we try to auto-login.
    if (Date.now() < lastSuccessfulLoginTime + 4 * 60 * 60000 && Date.now() > lastAutoLoginAttemptTime + 5000) {
      sessionStorage.setItem("lastAutoLoginAttemptTime", Date.now().toString());
      setTimeout(signInButtonClickHandler, 100);
    }
    content = (
      <Background>
        <AdminPanelText>Admin Panel</AdminPanelText>
        <Button variant="contained" onClick={signInButtonClickHandler}>
          Sign-in
        </Button>
        {errorMessage && <Notify severity="error" message={errorMessage} />}
      </Background>
    );
  } else {
    content = <AppView />;
  }
  return <>{content}</>;
};

export default LandingPage;
