/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { AlertColor } from "@mui/material";
import { IClientBuild, useClientBuildService } from "../../../services/registry/ClientBuildService";
import { ClientBuildsTable } from "./ClientBuildsTable";
import { ClientBuildForm, ClientBuildFormData, ClientBuildFormType } from "./ClientBuildForm";
import { ClientBuildCard } from "./ClientBuildCard";
import { LoadingOverlay } from "../../../components/LoadingOverlay";
import { YesNoPopup } from "../../../components/YesNoPopup";
import { Notify } from "../../../components/Notify";
import { EnvironmentPicker } from "../../../components/EnvironmentPicker";
import { IEnvironment } from "../../../services/registry/EnvironmentService";
import { AppContext } from "utils/AppContext";
import { useSearchParams } from "react-router-dom";

const ClientBuilds = () => {
  const clientBuildService = useClientBuildService();

  const [loading, setLoading] = useState(true);
  const [alertMessage, setAlertMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [clientBuilds, setClientBuilds] = useState<IClientBuild[]>([]);
  const [selectedItem, setSelectedItem] = useState<IClientBuild | undefined>(undefined);
  const [promoting, setPromoting] = useState(false);
  const [deletePopupVisible, setDeletePopupVisible] = useState<boolean>(false);
  const [clientBuildFormVisible, setClientBuildFormVisible] = useState(false);
  const [clientBuildFormType, setClientBuildFormType] = useState<ClientBuildFormType>("new");
  const [initialFilterQuery, setInitialFilterQuery] = useState<string>("");

  useEffect(() => {
    (async () => fetchClientBuilds())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchClientBuilds = async () => {
    try {
      setLoading(true);
      const { list } = await clientBuildService.listClientBuilds();
      setClientBuilds(list);
      setLoading(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Failed to get Client Builds.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const clientBuildFormAcceptHandler = async (data: ClientBuildFormData) => {
    if (clientBuildFormType === "new") {
      try {
        await clientBuildService.addClientBuild(data.name, data.version, data.branch, data.platform);
        setClientBuildFormVisible(false);
        await fetchClientBuilds();
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not add the new client build.");
        setAlertSeverity("error");
      }
    } else {
      try {
        await clientBuildService.editClientBuild(data.id, data.name, data.version, data.branch, data.platform);
        setClientBuildFormVisible(false);
        setSelectedItem({
          ...data,
          environment: selectedItem?.environment,
          creationDate: selectedItem?.creationDate ?? 0,
          commitHash: selectedItem?.commitHash,
        });
        await fetchClientBuilds();
      } catch (e: any) {
        setAlertMessage(e.debugMessage || "Could not edit the client build.");
        setAlertSeverity("error");
      }
    }
  };

  const deletePopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      setLoading(true);
      setDeletePopupVisible(false);
      await clientBuildService.removeClientBuild(selectedItem.id);
      setSelectedItem(undefined);
      await fetchClientBuilds();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not remove the selected Client Build.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const editButtonClickHandler = () => {
    setClientBuildFormVisible(true);
    setClientBuildFormType("edit");
  };

  const promoteButtonClickHandler = async (environment: IEnvironment) => {
    if (!selectedItem) {
      return;
    }
    try {
      await clientBuildService.promoteClientBuild(selectedItem.id, environment.name);
      await fetchClientBuilds();
      setSelectedItem({ ...selectedItem, environment: environment.name });
      setPromoting(false);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not remove the selected Client Build.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Client Builds");
    context?.setTargetAPI("registry");
  }, [context]);

  const [searchParams] = useSearchParams();
  useEffect(() => {
    const id = searchParams.get("id");
    if (id && clientBuilds) {
      const item = clientBuilds.find((t) => t.id == id);
      if (item) {
        setInitialFilterQuery(id);
      }
    }
  }, [searchParams, clientBuilds]);

  return (
    <>
      <ClientBuildsTable
        rows={clientBuilds}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onAddButtonClick={() => setClientBuildFormVisible(true)}
        initialFilterQuery={initialFilterQuery}
      />
      {selectedItem && (
        <ClientBuildCard
          content={selectedItem}
          onEditButtonClick={editButtonClickHandler}
          onDeleteButtonClick={() => setDeletePopupVisible(true)}
          onPromoteButtonClick={() => setPromoting(true)}
        />
      )}
      {promoting && (
        <EnvironmentPicker
          visible={promoting}
          dialogTitle="Promote Client Build to Environment:"
          actionButtonLabel="Promote"
          onCancel={() => setPromoting(false)}
          onAction={promoteButtonClickHandler}
        />
      )}
      <ClientBuildForm
        visible={clientBuildFormVisible}
        type={clientBuildFormType}
        initialData={clientBuildFormType === "edit" ? selectedItem : undefined}
        onDismiss={() => setClientBuildFormVisible(false)}
        onAccept={clientBuildFormAcceptHandler}
      />
      <YesNoPopup
        visible={deletePopupVisible}
        title={"Remove Client Build"}
        message={`Are you sure you want to remove the Client Build named <b>${selectedItem?.name}</b>?<br/>
        This Client Build will permanently become unavailable if you remove it.`}
        onNo={() => setDeletePopupVisible(false)}
        onYes={deletePopupYesHandler}
      />
      {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      {loading && <LoadingOverlay />}
    </>
  );
};

export default ClientBuilds;
