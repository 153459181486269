import { DateTime } from "luxon";
import { useContext } from "react";
import { useAuthUser } from "react-auth-kit";
import { buildGetOptions, buildPostOptions, fetchWithErrorHandling } from "services/FetchUtils";
import { AppContext } from "utils/AppContext";

const apiEndpointPrefix = "v1/backups/";
//Partial backups
export const listPartialBackups = async (baseURL: string, accessToken: string) => {
  return fetchWithErrorHandling(baseURL + `${apiEndpointPrefix}partial`, buildGetOptions(accessToken));
};

export const loadPartialBackup = async (baseURL: string, accessToken: string, key: string) => {
  return fetchWithErrorHandling(baseURL + `${apiEndpointPrefix}partial/content`, buildPostOptions(accessToken, { key }));
};

export const restorePartialBackup = async (baseURL: string, accessToken: string, dataStructureName: string, key: string) => {
  return fetchWithErrorHandling(
    baseURL + `${apiEndpointPrefix}partial/${dataStructureName}/restore`,
    buildPostOptions(accessToken, { key }),
  );
};

export const deletePartialBackup = async (baseURL: string, accessToken: string, dataStructureName: string, key: string) => {
  return fetchWithErrorHandling(
    baseURL + `${apiEndpointPrefix}partial/${dataStructureName}/delete`,
    buildPostOptions(accessToken, { key }),
  );
};

//Full backups
export const listFullBackups = async (baseURL: string, accessToken: string) => {
  return fetchWithErrorHandling(baseURL + `${apiEndpointPrefix}full`, buildGetOptions(accessToken));
};

export const loadFullBackup = async (baseURL: string, accessToken: string, backupId: string) => {
  return fetchWithErrorHandling(baseURL + `${apiEndpointPrefix}full/${backupId}`, buildGetOptions(accessToken));
};

export const restoreFullBackup = async (baseURL: string, accessToken: string, backupId: string) => {
  return fetchWithErrorHandling(baseURL + `${apiEndpointPrefix}full/restore/${backupId}`, buildPostOptions(accessToken));
};

export interface IFullBackupManifest {
  backupId: string;
  backupType: string;
  status: string;
  startDate: string;
  endDate?: string;
  epochTime: number;
  chunkSize: number;
  backupSize?: number;
  fileKeyList: string[];
}

export interface IPartialBackup {
  key: string;
  date: string;
  schemaVersion: string;
  entityId: string;
  type: string;
  files: string[];
}

interface PartialBackupPartitionModel {
  backupType: string;
  typeName: string;
  date: DateTime;
  entityId: string;
  schemaVersion: number;
  fileName: string;
}

interface PartialBackupPartitionKeys {
  [key: string]: unknown;
  backupType: string;
  typeName: string;
  year: number;
  month: number;
  day: number;
  entityId: string;
  schemaVersion: number;
  fileName: string;
}

export const fromS3Path = (path: string): PartialBackupPartitionModel => {
  const dict: PartialBackupPartitionKeys = path
    .split("/")
    .slice(0, -1)
    .map((partition) => partition.split("="))
    .reduce((acc, cur) => {
      acc[cur[0]] = cur[1];
      return acc;
    }, {} as PartialBackupPartitionKeys);

  dict["fileName"] = path.split("/").slice(-1)[0];
  const { backupType, typeName, year, month, day, entityId, schemaVersion, fileName } = dict;
  return {
    backupType,
    date: DateTime.fromJSDate(new Date(year, month - 1, day)),
    entityId,
    fileName,
    schemaVersion,
    typeName,
  } as PartialBackupPartitionModel;
};

export const useBackupService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  const appContext = useContext(AppContext);

  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";

  return {
    listPartialBackups: () => listPartialBackups(getBaseURL(), accessToken),
    loadPartialBackup: (key: string) => loadPartialBackup(getBaseURL(), accessToken, key),
    restorePartialBackup: (dataStructureName: string, keyId: string) =>
      restorePartialBackup(getBaseURL(), accessToken, dataStructureName, keyId),
    deletePartialBackup: (dataStructureName: string, keyId: string) =>
      deletePartialBackup(getBaseURL(), accessToken, dataStructureName, keyId),
    listFullBackups: () => listFullBackups(getBaseURL(), accessToken),
    loadFullBackup: (backupId: string) => loadFullBackup(getBaseURL(), accessToken, backupId),
    restoreFullBackup: (backupId: string) => restoreFullBackup(getBaseURL(), accessToken, backupId),
    fromS3Path: (path: string) => fromS3Path(path),
  };
};
