import * as React from "react";
import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, Link, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import Checkbox from "@mui/material/Checkbox";
import TablePagination from "@mui/material/TablePagination";
import { getComparator, Order, stableSort } from "../../../utils/Table";
import { IClientBuild } from "../../../services/registry/ClientBuildService";

interface HeadCell {
  id: keyof IClientBuild;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "branch",
    label: "Branch",
  },
  {
    id: "version",
    label: "Version",
  },
  {
    id: "platform",
    label: "Platform",
  },
  {
    id: "environment",
    label: "Environment",
  },
  {
    id: "creationDate",
    label: "Creation Date",
  },
  {
    id: "commitHash",
    label: "Commit Hash",
  },
];

interface ClientBuildsTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IClientBuild) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const ClientBuildsTableHead = ({ order, orderBy, onRequestSort }: ClientBuildsTableHeadProps) => {
  const createSortHandler = (property: keyof IClientBuild) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

interface ClientBuildsTableToolbarProps {
  onAddButtonClick: () => void;
  filterQuery: string;
  setFilterQuery: (value: string) => void;
}

const ClientBuildsTableToolbar = ({ onAddButtonClick, filterQuery, setFilterQuery }: ClientBuildsTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick} style={{ height: 40 }}>
        Add
      </Button>
      <TextField
        id="outlined-search"
        label="Search client builds"
        type="search"
        size="small"
        onChange={(e) => setFilterQuery(e.target.value)}
        sx={{ width: "360px", marginLeft: 1 }}
        value={filterQuery}
      />
    </Toolbar>
  );
};

export interface ClientBuildsTableProps {
  rows: IClientBuild[];
  selectedItem: IClientBuild | undefined;
  setSelectedItem: Dispatch<SetStateAction<IClientBuild | undefined>>;
  onAddButtonClick: () => void;
  initialFilterQuery: string | undefined;
}

export const ClientBuildsTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onAddButtonClick,
  initialFilterQuery,
}: ClientBuildsTableProps) => {
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IClientBuild>("creationDate");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterQuery, setFilterQuery] = useState<string>("");

  useEffect(() => {
    if (initialFilterQuery) {
      setFilterQuery(initialFilterQuery);
    }
  }, [initialFilterQuery]);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IClientBuild) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const selectionChangeHandler = (event: ChangeEvent<HTMLInputElement>, row: IClientBuild) => {
    setSelectedItem(event.target.checked ? row : undefined);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const filteredRows = useMemo(() => {
    const query = filterQuery.toLowerCase();
    return rows.filter((row) => {
      return (
        row.id.includes(query) ||
        row.name?.toLowerCase().includes(query) ||
        row.version?.toLowerCase().includes(query) ||
        row.branch?.toLowerCase().includes(query) ||
        row.platform?.toLowerCase().includes(query) ||
        (row.environment && row.environment.toLowerCase().includes(query)) ||
        (row.commitHash && row.commitHash.includes(query))
      );
    });
  }, [rows, filterQuery]);

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  const isSelected = (row: IClientBuild, selectedItem: IClientBuild | undefined) =>
    selectedItem !== undefined && row.id === selectedItem.id;

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <ClientBuildsTableToolbar
          onAddButtonClick={onAddButtonClick}
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
        />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <ClientBuildsTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `client-builds-table-checkbox-${index}`;
                const creationDate = DateTime.fromSeconds(row.creationDate).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={isSelected(row, selectedItem)}
                    sx={{ cursor: "pointer" }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        checked={isSelected(row, selectedItem)}
                        onChange={(event) => selectionChangeHandler(event, row)}
                      />
                    </TableCell>
                    <TableCell component="th" id={labelId} scope="row" padding="none">
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.branch}</TableCell>
                    <TableCell align="left">{row.version}</TableCell>
                    <TableCell align="left">{row.platform}</TableCell>
                    <TableCell align="left">{row.environment ? row.environment : "Unpromoted"}</TableCell>
                    <TableCell align="left">{creationDate}</TableCell>
                    <TableCell align="left">
                      <Link
                        onClick={() =>
                          window.open(`https://github.com/FortisGames/proj-townsfolk-game-platform/commit/${row.commitHash}`)
                        }
                      >
                        {row.commitHash}
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
};
