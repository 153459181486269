import { AlertColor, Snackbar, Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { IPartialBackup, useBackupService } from "services/backend/BackupService";
import { LoadingOverlay } from "components/LoadingOverlay";
import PartialBackupTable from "views/Backup/Partial/PartialBackupTable";
import * as React from "react";
import { AppContext } from "utils/AppContext";

interface IPartialBackupResponse {
  key: string;
  backupType: string;
  date: string;
  schemaVersion: string;
  fileName: string;
}
const PartialBackups = () => {
  const backupService = useBackupService();
  const [backupList, setBackupList] = useState<IPartialBackup[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [loading, setLoading] = useState(false);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };
  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Partial Backups");
    context?.setTargetAPI("environment");
    (async () => fetchBackupList())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const fetchBackupList = async () => {
    try {
      setLoading(true);
      const { data } = await backupService.listPartialBackups();
      const groupedData: IPartialBackup[] = Object.values(
        data.list.reduce(
          (acc: { [key: string]: IPartialBackup }, item: IPartialBackupResponse) => {
            const groupKey = item.key.substring(0, item.key.lastIndexOf("/"));

            if (!acc[groupKey]) {
              const partialBackupModel = backupService.fromS3Path(item.key);
              acc[groupKey] = {
                key: groupKey,
                date: item.date,
                schemaVersion: item.schemaVersion,
                entityId: partialBackupModel.entityId,
                type: partialBackupModel.typeName,
                files: [],
              };
            }

            if (!acc[groupKey].files.includes(item.fileName)) {
              acc[groupKey].files.push(item.fileName);
            }

            return acc;
          },
          {} as { [key: string]: IPartialBackup },
        ),
      );
      setBackupList(groupedData);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of partial backups.");
      setAlertSeverity("error");
      setBackupList([]);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Box style={{ width: "100%" }}>
        <PartialBackupTable rows={backupList}></PartialBackupTable>
      </Box>
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && <LoadingOverlay />}
    </>
  );
};
export default PartialBackups;
