import * as React from "react";
import {
  Toolbar,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Grid,
  TextField,
  Box,
  Paper,
  Collapse,
  IconButton,
  AlertColor,
} from "@mui/material";
import { ChangeEvent, useMemo, useState, MouseEvent } from "react";
import { getComparator, Order, stableSort } from "utils/Table";
import { IFullBackupManifest, useBackupService } from "services/backend/BackupService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { LoadingOverlay } from "components/LoadingOverlay";
import RestoreIcon from "@mui/icons-material/Restore";
import { YesNoPopup } from "components/YesNoPopup";
import { Notify } from "components/Notify";
import { DateTime } from "luxon";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";

export interface ITableProps {
  rows: IFullBackupManifest[];
}

interface HeadCell {
  id: keyof IFullBackupManifest | string;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}
const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "startDate",
    label: "Start Date",
  },
  {
    id: "endDate",
    label: "End Date",
  },
  {
    id: "status",
    label: "Status",
  },
  {
    id: "size",
    label: "Size (MB)",
  },
  {
    id: "actions",
    label: "",
  },
];
interface FullBackupTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IFullBackupManifest | string) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const FullBackupTableHead = ({ order, orderBy, onRequestSort }: FullBackupTableHeadProps) => {
  const createSortHandler = (property: keyof IFullBackupManifest | string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default function FullBackupTable({ rows }: ITableProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableFilter, setTableFilter] = useState<string>("");
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof IFullBackupManifest | string>("startDate");

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IFullBackupManifest | string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = useMemo(() => {
    setPage(0);
    return rows.filter((row) => {
      return row.backupId.toLowerCase().includes(tableFilter.toLowerCase());
    });
  }, [rows, tableFilter]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  function Row(prop: { item: IFullBackupManifest }) {
    const { item } = prop;
    const [open, setOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
    const backupService = useBackupService();
    const [loading, setLoading] = useState(false);
    const [selectedItem, setSelectedItem] = useState<IFullBackupManifest | undefined>(undefined);
    const [restoreConfirmationPopupVisible, setRestoreConfirmationPopupVisible] = useState<boolean>(false);

    const toggleCollapse = (event: React.MouseEvent) => {
      event.stopPropagation();
      setOpen(!open);
    };

    const restoreFullBackup = async () => {
      if (selectedItem) {
        setRestoreConfirmationPopupVisible(false);

        try {
          setLoading(true);
          await backupService.restoreFullBackup(selectedItem.backupId);
          setAlertMessage("Restoration completed.");
          setAlertSeverity("success");
        } catch (error) {
          console.log(error);
          setAlertMessage("Could not restore the full backup.");
          setAlertSeverity("error");
        } finally {
          setLoading(false);
        }
      }
    };

    const handleRestoreClick = (event: React.MouseEvent<unknown>, item: IFullBackupManifest) => {
      event.stopPropagation();
      setSelectedItem(item);
      setRestoreConfirmationPopupVisible(true);
    };

    return (
      <React.Fragment>
        <TableRow>
          <TableCell width={15}>
            <IconButton aria-label="expand row" size="small" onClick={toggleCollapse}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{item.backupId}</TableCell>
          <TableCell>
            {item.startDate ? DateTime.fromISO(item.startDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "-"}
          </TableCell>
          <TableCell>
            {item.endDate ? DateTime.fromISO(item.endDate).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS) : "-"}
          </TableCell>
          <TableCell>{item.status}</TableCell>
          <TableCell>{item.backupSize ? (item.backupSize / 1024 / 1024).toFixed(4) : 0}</TableCell>
          <TableCell>
            <Button
              size="small"
              variant="outlined"
              startIcon={<RestoreIcon />}
              onClick={(event) => handleRestoreClick(event, item)}
            >
              Restore
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Table size="small" aria-label="contents">
                  <TableHead>
                    <TableRow>
                      <TableCell>{`Contents: ${item.fileKeyList.length}`}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item.fileKeyList.map((file) => {
                      const fileName = file.substring(file.lastIndexOf("/") + 1);
                      return (
                        <TableRow key={file}>
                          <TableCell>{fileName}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <YesNoPopup
          visible={restoreConfirmationPopupVisible}
          title={"Restore full backup"}
          message={`Are you sure you want to restore this full backup?`}
          onNo={() => setRestoreConfirmationPopupVisible(false)}
          onYes={restoreFullBackup}
        />
        {loading && <LoadingOverlay />}
        {alertMessage && <Notify severity={alertSeverity} message={alertMessage} />}
      </React.Fragment>
    );
  }

  return (
    <Box sx={{ margin: 1 }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ pl: { sm: 1 }, pr: { xs: 1, sm: 1 }, pt: { sm: 1 }, pb: { sm: 1 } }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-search"
                label="Search full backups"
                type="search"
                onChange={(e) => setTableFilter(e.target.value)}
                sx={{ width: "360px" }}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table size="small">
            <FullBackupTableHead order={order} orderBy={orderBy} onRequestSort={requestSortHandler} rowCount={rows.length} />
            <TableBody>
              {visibleRows && visibleRows.map((item: IFullBackupManifest) => <Row key={item.backupId} item={item} />)}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
      </Paper>
    </Box>
  );
}
