import * as React from "react";
import { ChangeEvent, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { validateStringField, validateTfidField } from "../../utils/Validation";
import { IServerImage } from "../../services/registry/ServerImageService";
import { ServerImagePicker } from "../../components/ServerImagePicker";
import { GameServerType } from "../../services/backend/GameServerService";

export interface GameServerSpawnPopupProps {
  visible: boolean;
  onCancel: () => void;
  onAction: (
    containerName: string,
    serverType?: GameServerType,
    serverImage?: IServerImage,
    neighborhoodId?: string,
    townId?: string,
    expeditionId?: string,
    cityId?: string,
  ) => Promise<void>;
}

export const GameServerSpawnPopup = ({ visible, onCancel, onAction }: GameServerSpawnPopupProps) => {
  const refContainerName = useRef<TextFieldProps>(null);
  const [containerNameError, setContainerNameError] = useState<string | undefined>(undefined);
  const [useServerImage, setUseServerImage] = useState<boolean>(false);
  const [selectedServerImage, setSelectedServerImage] = useState<IServerImage | undefined>(undefined);
  const [serverType, setServerType] = useState<GameServerType | "undefined">("undefined");
  const refNeighborhoodId = useRef<TextFieldProps>(null);
  const [neighborhoodIdError, setNeighborhoodIdError] = useState<string | undefined>(undefined);
  const refTownId = useRef<TextFieldProps>(null);
  const [townIdError, setTownIdError] = useState<string | undefined>(undefined);
  const refExpeditionId = useRef<TextFieldProps>(null);
  const [expeditionIdError, setExpeditionIdError] = useState<string | undefined>(undefined);
  const refCityId = useRef<TextFieldProps>(null);
  const [cityIdError, setCityIdError] = useState<string | undefined>(undefined);

  const validateContainerName = (value: string) => validateStringField(value, setContainerNameError, 3, 999);
  const validateNeighborhoodId = (value: string) => validateTfidField(value, setNeighborhoodIdError);
  const validateTownId = (value: string) => validateTfidField(value, setTownIdError);
  const validateExpeditionId = (value: string) => validateTfidField(value, setExpeditionIdError);
  const validateCityId = (value: string) => validateTfidField(value, setCityIdError);

  const containerNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (containerNameError) {
      validateContainerName(event.target.value);
    }
  };
  const neighborhoodIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (neighborhoodIdError) {
      validateNeighborhoodId(event.target.value);
    }
  };
  const townIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (townIdError) {
      validateTownId(event.target.value);
    }
  };
  const expeditionIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (expeditionIdError) {
      validateExpeditionId(event.target.value);
    }
  };
  const cityIdChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (cityIdError) {
      validateCityId(event.target.value);
    }
  };

  const handleSpawn = async () => {
    if (!refContainerName.current) return;

    const containerName = validateContainerName(refContainerName.current.value as string);
    if (!containerName) {
      return;
    }

    let neighborhoodId = undefined;
    if (serverType === GameServerType.NEIGHBORHOOD) {
      if (!refNeighborhoodId.current) return;
      neighborhoodId = validateNeighborhoodId(refNeighborhoodId.current.value as string);
      if (!neighborhoodId) {
        return;
      }
    }

    let townId = undefined;
    if (serverType === GameServerType.TOWN) {
      if (!refTownId.current) return;
      townId = validateTownId(refTownId.current.value as string);
      if (!townId) {
        return;
      }
    }

    let expeditionId = undefined;
    if (serverType === GameServerType.EXPEDITION) {
      if (!refExpeditionId.current) return;
      expeditionId = validateExpeditionId(refExpeditionId.current.value as string);
      if (!expeditionId) {
        return;
      }
    }

    let cityId = undefined;
    if (serverType === GameServerType.CITY) {
      if (!refCityId.current) return;
      cityId = validateCityId(refCityId.current.value as string);
      if (!cityId) {
        return;
      }
    }

    await onAction(
      containerName,
      serverType === "undefined" ? undefined : serverType,
      useServerImage ? selectedServerImage : undefined,
      neighborhoodId,
      townId,
      expeditionId,
      cityId,
    );
  };

  return (
    <Dialog open={visible} fullWidth maxWidth="md">
      <DialogTitle>Spawn Game Server</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          fullWidth
          variant="standard"
          inputRef={refContainerName}
          id="name"
          label="Name"
          type="text"
          error={containerNameError !== undefined}
          helperText={containerNameError}
          onChange={containerNameChangeHandler}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={useServerImage}
              onChange={(event) => setUseServerImage(event.target.checked)}
              color="primary"
            />
          }
          label="Choose a Server Image"
          style={{ marginTop: 8 }}
        />

        {useServerImage && <ServerImagePicker onImageSelected={(image) => setSelectedServerImage(image)} />}

        <TextField
          select
          margin="dense"
          fullWidth
          variant="standard"
          id="serverType"
          label="Server Type"
          value={serverType}
          onChange={(event) => setServerType(event.target.value as GameServerType)}
          style={{ marginTop: 16 }}
        >
          <MenuItem key={"undefined"} value={"undefined"}>
            {"Not set (start the server in standby state)"}
          </MenuItem>
          {Object.values(GameServerType).map((typeOption) => (
            <MenuItem key={typeOption} value={typeOption}>
              {typeOption}
            </MenuItem>
          ))}
        </TextField>

        {serverType === GameServerType.NEIGHBORHOOD && (
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            id="neighborhoodId"
            label="Neighborhood ID"
            inputRef={refNeighborhoodId}
            error={neighborhoodIdError !== undefined}
            helperText={neighborhoodIdError}
            onChange={neighborhoodIdChangeHandler}
          />
        )}
        {serverType === GameServerType.TOWN && (
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            id="townId"
            label="Town ID"
            inputRef={refTownId}
            error={townIdError !== undefined}
            helperText={townIdError}
            onChange={townIdChangeHandler}
          />
        )}
        {serverType === GameServerType.EXPEDITION && (
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            id="expeditionId"
            label="Expedition ID"
            inputRef={refExpeditionId}
            error={expeditionIdError !== undefined}
            helperText={expeditionIdError}
            onChange={expeditionIdChangeHandler}
          />
        )}
        {serverType === GameServerType.CITY && (
          <TextField
            margin="dense"
            fullWidth
            variant="standard"
            id="cityId"
            label="City ID"
            inputRef={refCityId}
            error={cityIdError !== undefined}
            helperText={cityIdError}
            onChange={cityIdChangeHandler}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={onCancel} color="error">
          Cancel
        </Button>
        <Button variant="outlined" onClick={handleSpawn}>
          Spawn
        </Button>
      </DialogActions>
    </Dialog>
  );
};
