import * as React from "react";
import { ChangeEvent, Dispatch, MouseEvent, SetStateAction, useMemo, useState } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, Link, Menu, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import { MoreVert } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { getComparator, Order, stableSort } from "../../utils/Table";
import { GameServerState, IGameServer } from "../../services/backend/GameServerService";
import { GameServerStateIndicator } from "./GameServerStateIndicator";
import RefreshIcon from "@mui/icons-material/Refresh";

type GameServersTableHeadProperties = keyof IGameServer | "serverImageName" | "onlinePlayerCount" | "totalPlayerCount";

interface HeadCell {
  id: GameServersTableHeadProperties;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "containerName",
    label: "Container Name",
  },
  {
    id: "serverImageId",
    label: "Server Image ID",
  },
  {
    id: "ipAddress",
    label: "IP Address",
  },
  {
    id: "exposedPort",
    label: "Port",
  },
  {
    id: "creationDate",
    label: "Creation Date",
    align: "right",
  },
  {
    id: "onlinePlayerCount",
    label: "Online Players",
    align: "right",
  },
  {
    id: "totalPlayerCount",
    label: "Total Players",
    align: "right",
  },
  {
    id: "state",
    label: "State",
    align: "right",
  },
];

interface GameServersTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: GameServersTableHeadProperties) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const GameServersTableHead = ({ order, orderBy, onRequestSort }: GameServersTableHeadProps) => {
  const createSortHandler = (property: GameServersTableHeadProperties) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="normal" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="normal" />
      </TableRow>
    </TableHead>
  );
};

interface GameServersTableToolbarProps {
  onSpawnButtonClick: () => void;
  onRefreshButtonClick: () => void;
}

const GameServersTableToolbar = ({ onSpawnButtonClick, onRefreshButtonClick }: GameServersTableToolbarProps) => {
  return (
    <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }}>
      <Button variant="outlined" startIcon={<AddIcon />} onClick={onSpawnButtonClick}>
        Spawn
      </Button>
      <Button variant="outlined" startIcon={<RefreshIcon />} onClick={onRefreshButtonClick} sx={{ marginLeft: 1 }}>
        Refresh
      </Button>
    </Toolbar>
  );
};

export interface GameServersTableProps {
  rows: IGameServer[];
  selectedItem: IGameServer | undefined;
  setSelectedItem: Dispatch<SetStateAction<IGameServer | undefined>>;
  onSpawnButtonClick: () => void;
  onRefreshButtonClick: () => void;
  onStopMenuClick: () => void;
  onRestartMenuClick: () => void;
  onTerminateMenuClick: () => void;
}

export const GameServersTable = ({
  rows,
  selectedItem,
  setSelectedItem,
  onSpawnButtonClick,
  onRefreshButtonClick,
  onStopMenuClick,
  onRestartMenuClick,
  onTerminateMenuClick,
}: GameServersTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<GameServersTableHeadProperties>("containerName");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);

  const requestSortHandler = (event: MouseEvent<unknown>, property: GameServersTableHeadProperties) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const menuOpenHandler = (event: MouseEvent<HTMLElement>, item: IGameServer) => {
    setMenuAnchor(event.target as Element);
    setSelectedItem(item);
  };

  const menuCloseHandler = () => {
    setMenuAnchor(undefined);
  };

  const terminateMenuClickHandler = () => {
    onTerminateMenuClick();
    menuCloseHandler();
  };

  const stopMenuClickHandler = () => {
    onStopMenuClick();
    menuCloseHandler();
  };

  const restartMenuClickHandler = () => {
    onRestartMenuClick();
    menuCloseHandler();
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return stableSort(rows as any, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [rows, order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <GameServersTableToolbar onSpawnButtonClick={onSpawnButtonClick} onRefreshButtonClick={onRefreshButtonClick} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <GameServersTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `game-servers-table-checkbox-${index}`;
                const creationDate = DateTime.fromMillis(row.creationDate).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                const onlinePlayerCount = row.activePlayers || "N/A";
                const totalPlayerCount = row.playerIds.length;
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={index} sx={{ cursor: "pointer" }}>
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} scope="row" padding="none" style={{ fontFamily: "monospace" }}>
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.containerName}</TableCell>
                    <TableCell align="left" style={{ fontFamily: "monospace" }}>
                      <Link onClick={() => window.open(`${window.location.origin}/server-images/?id=${row.serverImageId}`)}>
                        {row.serverImageId}
                      </Link>
                    </TableCell>
                    <TableCell align="left">{row.ipAddress}</TableCell>
                    <TableCell align="left">{row.exposedPort}</TableCell>
                    <TableCell align="right">{creationDate}</TableCell>
                    <TableCell align="right">{onlinePlayerCount}</TableCell>
                    <TableCell align="right">{totalPlayerCount}</TableCell>
                    <TableCell align="right">
                      <GameServerStateIndicator state={row.state || GameServerState.UNKNOWN} />
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(e) => menuOpenHandler(e, row)}>
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
        <Menu anchorEl={menuAnchor} keepMounted open={menuAnchor !== undefined} onClose={() => menuCloseHandler()}>
          <MenuItem
            onClick={() => stopMenuClickHandler()}
            disabled={selectedItem === undefined || selectedItem.state === GameServerState.STOPPED}
          >
            <ListItemIcon>
              <StopIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Stop</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => restartMenuClickHandler()}
            disabled={selectedItem === undefined || selectedItem.state === GameServerState.RUNNING}
          >
            <ListItemIcon>
              <PlayArrowIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Restart</Typography>
          </MenuItem>
          <MenuItem onClick={() => terminateMenuClickHandler()} disabled={selectedItem === undefined}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Terminate</Typography>
          </MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
};
