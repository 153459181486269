import * as React from "react";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  validateUrlField,
  validateIntegerField,
  validateSelectField,
  validateNameField,
  formatError,
} from "../../../utils/Validation";
import { EnvType, envTypes, IEnvironment } from "../../../services/registry/EnvironmentService";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps } from "@mui/material";

export type EnvironmentFormType = "new" | "edit";

export interface EnvironmentFormData {
  name: string;
  baseURL: string;
  stage: number;
  ttl?: number;
  clientBranch?: string;
  useTerraform: boolean;
  ownerEmail?: string;
}

export interface EnvironmentFormProps {
  visible: boolean;
  type: EnvironmentFormType;
  initialData?: IEnvironment;
  onDismiss: () => void;
  onAccept: (data: EnvironmentFormData) => void;
}

export const EnvironmentFormField = (props: Omit<TextFieldProps, "variant">) => {
  return <TextField autoFocus margin="dense" fullWidth variant="standard" {...props} />;
};

export const EnvironmentForm = ({ visible, type, initialData, onDismiss, onAccept }: EnvironmentFormProps) => {
  const refName = useRef<TextFieldProps>(null);
  const refBaseURL = useRef<TextFieldProps>(null);
  const refEnvType = useRef<SelectProps>(null);
  const refStage = useRef<TextFieldProps>(null);
  const refClientBranch = useRef<TextFieldProps>(null);
  const refTTL = useRef<TextFieldProps>(null);
  const refOwnerEmail = useRef<TextFieldProps>(null);

  const [nameError, setNameError] = useState<string | undefined>(undefined);
  const [baseUrlError, setBaseUrlError] = useState<string | undefined>(undefined);
  const [envTypeError, setEnvTypeError] = useState<string | undefined>(undefined);
  const [stageError, setStageError] = useState<string | undefined>(undefined);
  const [clientBranchError, setClientBranchError] = useState<string | undefined>(undefined);
  const [ttlError, setTTLError] = useState<string | undefined>(undefined);
  const [ownerEmailError, setOwnerEmailError] = useState<string | undefined>(undefined);

  const [useTerraform, setUseTerraform] = useState<boolean>(type === "new");
  const [stage, setStage] = useState<EnvType | undefined>(undefined);

  useEffect(() => {
    if (type === "edit") {
      setUseTerraform(false);
    }
  }, [type]);

  useEffect(() => {
    if (!useTerraform) {
      setStageError(undefined);
    }
  }, [useTerraform]);

  useEffect(() => {
    if (visible) {
      setNameError(undefined);
      setBaseUrlError(undefined);
      setStageError(undefined);
      setClientBranchError(undefined);
      setTTLError(undefined);
      setOwnerEmailError(undefined);
    }
  }, [visible]);

  const dialogCloseHandler = (event: unknown, reason: string) => {
    if (reason !== "backdropClick") {
      onDismiss();
    }
  };

  const validateName = (value: string) => validateNameField(value, setNameError, 2, 60);
  const validateBaseURL = (value: string) => validateUrlField(value, setBaseUrlError);
  const validateEnvType = (value: string) =>
    validateSelectField(
      value,
      setEnvTypeError,
      envTypes.map((envType) => envType.name),
    );
  const validateStage = (value: string) => {
    const [minStage, maxStage] = environmentStageRange();
    return validateIntegerField(value, setStageError, minStage, maxStage);
  };
  const validateClientBranch = (value: string): string | undefined => {
    setClientBranchError(undefined);
    if (!value) {
      return "";
    } else {
      return value; // TODO: more checks?
    }
  };
  const validateTTL = (value: string) => {
    return validateIntegerField(value, setTTLError, 0, 999);
  };
  const validateOwnerEmail = (value: string) => {
    if (!value) {
      setOwnerEmailError(undefined);
      return "";
    } else if (!value.endsWith("@fortisgames.com")) {
      formatError("Must be a valid Fortis email address.", setOwnerEmailError);
      return undefined;
    } else {
      setOwnerEmailError(undefined);
      return value;
    }
  };

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => nameError && validateName(event.target.value);
  const baseUrlChangeHandler = (event: ChangeEvent<HTMLInputElement>) => baseUrlError && validateBaseURL(event.target.value);
  const envTypeChangeHandler = (event: SelectChangeEvent) => {
    if (envTypeError) {
      validateEnvType(event.target.value);
    }
    if (event.target.value === "Prod") {
      setUseTerraform(false);
    }

    const currentStage = envTypes.find((env) => env.name === event.target.value);

    if (currentStage) {
      setStage(currentStage);
    }
  };
  const stageChangeHandler = (event: SelectChangeEvent) => {
    if (stageError) {
      validateStage(event.target.value);
    }
  };
  const clientBranchChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    clientBranchError && validateClientBranch(event.target.value);
  const ttlChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    if (ttlError) {
      validateTTL(event.target.value);
    }
  };
  const ownerEmailChangeHandler = (event: ChangeEvent<HTMLInputElement>) =>
    ownerEmailError && validateOwnerEmail(event.target.value);

  const environmentStageRange = () => {
    let minStage = 1;
    let maxStage = useTerraform ? 7 : 9;
    const selectedEnvType = refEnvType.current?.value;
    const envType = envTypes.find((envType) => envType.name === selectedEnvType);
    if (envType) {
      minStage = Math.min(...envType.values);
      maxStage = Math.max(...envType.values);
    }
    return [minStage, maxStage];
  };

  const okButtonClickHandler = () => {
    const name = validateName(refName.current?.value as string);
    const stage = validateStage(refStage.current?.value as string);
    let clientBranch = validateClientBranch(refClientBranch.current?.value as string);
    let ttl = validateTTL(refTTL.current?.value as string);
    let ownerEmail = validateOwnerEmail(refOwnerEmail.current?.value as string);
    if (type === "new") {
      ownerEmail = "";
    }

    let baseURL: string | undefined = "";
    if (useTerraform) {
      setBaseUrlError(undefined);
    } else {
      baseURL = validateBaseURL(refBaseURL.current?.value as string);
      if (!baseURL) {
        return;
      }
    }

    if (!name || !stage || clientBranch === undefined || ttl === undefined || ownerEmail === undefined) {
      return;
    }
    if (clientBranch === "") {
      clientBranch = undefined;
    }
    if (ttl === 0) {
      ttl = undefined;
    }
    if (ownerEmail === "") {
      ownerEmail = undefined;
    }
    onAccept({ name, baseURL, stage, ttl, clientBranch, useTerraform, ownerEmail });
  };

  return (
    <Dialog open={visible} onClose={dialogCloseHandler}>
      <DialogTitle>{(type === "new" ? "New" : "Edit") + " Environment"}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please fill up the information below:</DialogContentText>
        <EnvironmentFormField
          inputRef={refName}
          id="name"
          label="Name"
          type="text"
          defaultValue={initialData?.name}
          error={nameError !== undefined}
          helperText={nameError}
          onChange={nameChangeHandler}
          disabled={type === "edit"}
        />
        {!useTerraform && (
          <EnvironmentFormField
            inputRef={refBaseURL}
            id="baseURL"
            label="Base URL"
            type="url"
            defaultValue={initialData?.baseURL}
            error={baseUrlError !== undefined}
            helperText={baseUrlError}
            onChange={baseUrlChangeHandler}
          />
        )}

        <FormControl variant="standard" fullWidth>
          <InputLabel id="type-label">Environment Type</InputLabel>
          <Select
            labelId="type-label"
            id="type-selector"
            inputRef={refEnvType}
            label="Environment Type"
            error={envTypeError !== undefined}
            onChange={(e) => envTypeChangeHandler(e)}
            defaultValue=""
          >
            {envTypes.map((envType) => (
              <MenuItem key={envType.name} value={envType.name}>
                {envType.name}
              </MenuItem>
            ))}
          </Select>
          {envTypeError && <FormHelperText error={true}>{envTypeError}</FormHelperText>}
        </FormControl>

        <FormControl variant="standard" fullWidth>
          <InputLabel id="stage-label">Stage</InputLabel>
          <Select
            labelId="stage-label"
            id="type-selector"
            inputRef={refStage}
            label="Stage"
            error={stageError !== undefined}
            onChange={(e) => stageChangeHandler(e)}
            defaultValue=""
          >
            {stage?.values.map((value) => (
              <MenuItem key={`${refEnvType.current?.value}_${value}`} value={value}>
                {value}
              </MenuItem>
            ))}
          </Select>
          {envTypeError && <FormHelperText error={true}>{envTypeError}</FormHelperText>}
        </FormControl>

        <EnvironmentFormField
          inputRef={refClientBranch}
          id="clientBranch"
          label="Client branch"
          type="text"
          defaultValue={initialData?.clientBranch || ""}
          error={clientBranchError !== undefined}
          helperText={clientBranchError}
          onChange={clientBranchChangeHandler}
        />

        <EnvironmentFormField
          inputRef={refTTL}
          id="ttl"
          label="Reminder to terminate (in days, 0 to disable)"
          type="number"
          defaultValue={initialData?.ttl || 0}
          error={ttlError !== undefined}
          helperText={ttlError}
          onChange={ttlChangeHandler}
        />

        {type === "edit" && (
          <EnvironmentFormField
            inputRef={refOwnerEmail}
            id="ownerEmail"
            label="Owner email"
            type="text"
            defaultValue={initialData?.ownerEmail || ""}
            error={ownerEmailError !== undefined}
            helperText={ownerEmailError}
            onChange={ownerEmailChangeHandler}
          />
        )}

        {type === "new" && (
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              marginTop: 12,
              marginLeft: -12,
            }}
          >
            <Checkbox
              color="primary"
              checked={refEnvType.current?.value === "Prod" ? false : useTerraform}
              onChange={() => setUseTerraform(!useTerraform)}
              disabled={refEnvType.current?.value === "Prod"}
            />
            <Typography variant="body2">Use Terraform to create that environment on AWS</Typography>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button onClick={okButtonClickHandler}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};
