import { fetchWithErrorHandling, buildPostOptions, buildGetOptions } from "../FetchUtils";
import { useAuthUser } from "react-auth-kit";
import { useContext } from "react";
import { AppContext } from "../../utils/AppContext";

export enum GameServerState {
  UNKNOWN = "unknown",
  RUNNING = "running",
  STANDBY = "standby",
  UNHEALTHY = "unhealthy",
  STOPPED = "stopped",
}

export enum GameServerType {
  NEIGHBORHOOD = "neighborhood",
  TOWN = "town",
  EXPEDITION = "expedition",
  CITY = "city",
}

export interface IGameServer {
  id: string;
  containerName: string;
  creationDate: number;
  lastHeartbeatTime: number;
  serverImageId: string;
  branch: string;
  ipAddress: string;
  exposedPort: number;
  state: GameServerState;
  pendingActionIds: string[];
  otherActionIds: string[];
  playerIds: string[];
  type?: GameServerType;
  activePlayers?: number;
  firstStartTime: number;
  lastRestartTime?: number;
  lastActivePlayerReportTime?: number;
  neighborhoodId?: string;
  townId?: string;
  expeditionId?: string;
  cityId?: string;
}

export enum GameServerAction {
  BAN_ACCOUNT = "BanAccount",
  UPDATE_MAINTENANCE_MODE = "UpdateMaintenanceMode",
  REFRESH_PLAYER_METADATA = "RefreshPlayerMetadata",
}

export enum GameServerActionState {
  UNDEFINED = "undefined",
  PENDING = "pending",
  RUNNING = "running",
  SUCCEEDED = "succeeded",
  FAILED = "failed",
}

export interface IGameServerAction {
  id: string;
  initTimeMs: number; // in ms since the Unix Epoch
  updateTimeMs: number;
  playerIds?: string[];
  action: GameServerAction;
  extraArgs?: string[];
  statesByServerId: Record<string, GameServerActionState>;
}

export const list = async (accessToken: string, baseURL: string) => {
  return fetchWithErrorHandling(baseURL + "v1/game-server/list", buildGetOptions(accessToken));
};

export const spawn = async (
  accessToken: string,
  baseURL: string,
  containerName: string,
  serverType?: GameServerType,
  serverImageId?: string,
  neighborhoodId?: string,
  townId?: string,
  expeditionId?: string,
  cityId?: string,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/game-server/spawn",
    buildPostOptions(accessToken, {
      containerName,
      serverType,
      serverImageId,
      neighborhoodId,
      townId,
      expeditionId,
      cityId,
    }),
  );
};

export const terminate = async (accessToken: string, baseURL: string, id: string) => {
  return fetchWithErrorHandling(baseURL + "v1/game-server/terminate", buildPostOptions(accessToken, { id }));
};

export const stop = async (accessToken: string, baseURL: string, id: string) => {
  return fetchWithErrorHandling(baseURL + "v1/game-server/stop", buildPostOptions(accessToken, { id }));
};

export const restart = async (accessToken: string, baseURL: string, id: string) => {
  return fetchWithErrorHandling(baseURL + "v1/game-server/restart", buildPostOptions(accessToken, { id }));
};

export const sendAction = async (
  accessToken: string,
  baseURL: string,
  action: GameServerAction,
  serverIds: string[] | undefined,
  playerIds: string[] | undefined,
  extraArgs: string[] | undefined,
) => {
  return fetchWithErrorHandling(
    baseURL + "v1/game-server/send-action",
    buildPostOptions(accessToken, {
      action,
      serverIds,
      playerIds,
      extraArgs,
    }),
  );
};

export const listActions = async (accessToken: string, baseURL: string) => {
  return fetchWithErrorHandling(baseURL + "v1/game-server/list-actions", buildPostOptions(accessToken, {}));
};

export const useGameServerService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;
  const appContext = useContext(AppContext);

  const getBaseURL = () => appContext?.selectedEnvironment?.baseURL || "http://localhost:3001/";

  return {
    list: () => list(accessToken, getBaseURL()),
    spawn: (
      containerName: string,
      serverType?: GameServerType,
      serverImageId?: string,
      neighborhoodId?: string,
      townId?: string,
      expeditionId?: string,
      cityId?: string,
    ) =>
      spawn(
        accessToken,
        getBaseURL(),
        containerName,
        serverType,
        serverImageId,
        neighborhoodId,
        townId,
        expeditionId,
        cityId,
      ),
    terminate: (id: string) => terminate(accessToken, getBaseURL(), id),
    stop: (id: string) => stop(accessToken, getBaseURL(), id),
    restart: (id: string) => restart(accessToken, getBaseURL(), id),
    sendAction: (
      action: GameServerAction,
      serverIds: string[] | undefined,
      playerIds: string[] | undefined,
      extraArgs: string[] | undefined,
    ) => sendAction(accessToken, getBaseURL(), action, serverIds, playerIds, extraArgs),
    listActions: () => listActions(accessToken, getBaseURL()),
  };
};
