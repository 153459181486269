/* eslint-disable  @typescript-eslint/no-explicit-any */
import * as React from "react";
import { useEffect, useState } from "react";
import { Alert, AlertColor, Snackbar } from "@mui/material";
import { AppContext } from "utils/AppContext";
import { GameServersTable } from "./GameServersTable";
import { GameServerSpawnPopup } from "./GameServerSpawnPopup";
import { GameServerType, IGameServer, useGameServerService } from "../../services/backend/GameServerService";
import { IServerImage } from "../../services/registry/ServerImageService";
import { YesNoPopup } from "../../components/YesNoPopup";
import { LoadingOverlay } from "../../components/LoadingOverlay";

const GameServers = () => {
  const gameServerService = useGameServerService();

  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [rows, setRows] = useState<IGameServer[]>([]);
  const [selectedItem, setSelectedItem] = useState<IGameServer | undefined>(undefined);
  const [gameServerSpawnPopupVisible, setGameServerSpawnPopupVisible] = useState<boolean>(false);
  const [terminatePopupVisible, setTerminatePopupVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const fetchRows = async () => {
    try {
      const { list } = await gameServerService.list();
      setRows(list as IGameServer[]);
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of game servers.");
      setAlertSeverity("error");
    }
  };

  const refreshButtonClickHandler = async () => {
    setLoading(true);
    await fetchRows();
    setLoading(false);
  };

  const spawnButtonClickHandler = () => {
    setGameServerSpawnPopupVisible(true);
  };

  const gameServerSpawnPopupCancelHandler = () => {
    setGameServerSpawnPopupVisible(false);
  };

  const gameServerSpawnPopupActionHandler = async (
    containerName: string,
    serverType?: GameServerType,
    serverImage?: IServerImage,
    neighborhoodId?: string,
    townId?: string,
    expeditionId?: string,
    cityId?: string,
  ) => {
    try {
      setLoading(true);
      setGameServerSpawnPopupVisible(false);
      await gameServerService.spawn(
        containerName,
        serverType,
        serverImage?.id,
        neighborhoodId,
        townId,
        expeditionId,
        cityId,
      );
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not spawn the new game server.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const terminatePopupNoHandler = () => {
    setTerminatePopupVisible(false);
  };

  const terminatePopupYesHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      setLoading(true);
      setTerminatePopupVisible(false);
      await gameServerService.terminate(selectedItem.id);
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not terminate the selected game server.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const terminateMenuClickHandler = () => {
    setTerminatePopupVisible(true);
  };

  const stopMenuClickHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      setLoading(true);
      await gameServerService.stop(selectedItem.id);
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not stop the selected game server.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const restartMenuClickHandler = async () => {
    if (!selectedItem) {
      return;
    }
    try {
      setLoading(true);
      await gameServerService.restart(selectedItem.id);
      await fetchRows();
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not restart the selected game server.");
      setAlertSeverity("error");
    } finally {
      setLoading(false);
    }
  };

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Game Servers");
    context?.setTargetAPI("environment");
    (async () => fetchRows())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  return (
    <>
      <GameServersTable
        rows={rows}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        onSpawnButtonClick={spawnButtonClickHandler}
        onRefreshButtonClick={refreshButtonClickHandler}
        onTerminateMenuClick={terminateMenuClickHandler}
        onStopMenuClick={stopMenuClickHandler}
        onRestartMenuClick={restartMenuClickHandler}
      />
      <GameServerSpawnPopup
        visible={gameServerSpawnPopupVisible}
        onCancel={gameServerSpawnPopupCancelHandler}
        onAction={gameServerSpawnPopupActionHandler}
      />
      <YesNoPopup
        visible={terminatePopupVisible}
        title={"Terminate Game Server"}
        message={`Are you sure you want to terminate the Game Server named <b>${selectedItem?.containerName}</b>?<br/>
        If you terminate this Game Server, a long cold-start will be required to restart it.`}
        onNo={terminatePopupNoHandler}
        onYes={terminatePopupYesHandler}
      />
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && <LoadingOverlay />}
    </>
  );
};

export default GameServers;
