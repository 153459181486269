/* eslint-disable  @typescript-eslint/no-explicit-any */

import { AsyncEvent } from "ts-events";
import { useEffect, useState } from "react";

const getCommonOptions = (method: string, accessToken?: string, body?: unknown, formData?: unknown) => {
  const options = {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    redirect: "follow",
    referrerPolicy: "no-referrer",
    headers: {},
  } as any;
  if (accessToken) {
    options.headers["Authorization"] = `Bearer ${accessToken}`;
  }
  if (formData) {
    options.body = formData;
  } else if (body) {
    options.body = JSON.stringify(body);
    options.headers["Content-Type"] = "application/json";
  }

  return options as RequestInit;
};

export const buildGetOptions = (accessToken?: string) => getCommonOptions("GET", accessToken);

export const buildPostOptions = (accessToken?: string, body?: unknown, formData?: unknown) =>
  getCommonOptions("POST", accessToken, body, formData);

export const buildDeleteOptions = (accessToken?: string) => getCommonOptions("DELETE", accessToken);

export class BackendError extends Error {
  constructor(
    private readonly response: Response,
    public readonly debugMessage?: string,
  ) {
    super(debugMessage);

    Object.setPrototypeOf(this, BackendError.prototype);
  }

  public getResponse(): Response {
    return this.response;
  }
}

class ConnectionStateManager {
  private _isConnected: boolean;
  private _changeEvent: AsyncEvent<boolean>;
  private _connectionCheckIntervalId: any;

  public get isConnected(): boolean {
    return this._isConnected;
  }

  public set isConnected(state: boolean) {
    const hasChange = state != this._isConnected;
    this._isConnected = state;
    if (hasChange) {
      this._changeEvent.post(state);
      if (this._isConnected) {
        if (this._connectionCheckIntervalId !== undefined) {
          clearInterval(this._connectionCheckIntervalId);
        }
        this._connectionCheckIntervalId = setInterval(this.connectionCheckHandler.bind(this), 5000);
      } else {
        if (this._connectionCheckIntervalId === undefined) {
          this._connectionCheckIntervalId = setInterval(this.connectionCheckHandler.bind(this), 5000);
        }
      }
    }
  }

  public get onChange(): AsyncEvent<boolean> {
    return this._changeEvent;
  }

  constructor() {
    this._isConnected = true;
    this._changeEvent = new AsyncEvent<boolean>();
    this._connectionCheckIntervalId = undefined;
  }

  public async waitUntilConnected(): Promise<void> {
    return new Promise((resolve) => {
      this._changeEvent.attach((isConnected) => {
        if (isConnected) {
          resolve();
        }
      });
    });
  }

  private connectionCheckHandler(): void {
    // TODO: We could try to recover automatically by pinging the registry and try to redo an auto-login once up.
    // But for now, just informing the user that they're disconnected and they should refresh the page is good enough.
  }
}

const connectionStateManager = new ConnectionStateManager();

export function useConnectionStateManager(): [boolean] {
  const [isConnected, setIsConnected] = useState(connectionStateManager.isConnected);

  useEffect(() => {
    const handleConnectionChange = (newState: boolean) => {
      setIsConnected(newState);
    };

    connectionStateManager.onChange.attach(handleConnectionChange);

    // Clean up on component unmount
    return () => {
      connectionStateManager.onChange.detach(handleConnectionChange);
    };
  }, []);

  return [isConnected];
}

export const fetchWithErrorHandling = async (
  input: RequestInfo | URL,
  init?: RequestInit,
  json = true,
  text = false,
): Promise<any> => {
  if (!connectionStateManager.isConnected) {
    await connectionStateManager.waitUntilConnected();
  }
  const res = await fetch(input, init);
  if (!res.ok) {
    if (res.status === 403) {
      connectionStateManager.isConnected = false;
    }
    let debugMessage;
    try {
      const e = await res.json();
      debugMessage = e.debugMessage;
      console.error(`[BACKEND] ${debugMessage}`, e);
    } catch (e) {
      // ignore
    }
    throw new BackendError(res, debugMessage);
  }

  if (json) {
    return await res.json();
  }

  if (text) {
    return await res.text();
  }

  return res;
};
