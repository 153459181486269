import React, { ReactElement } from "react";
import { BrowserRouter as Router, Route, Routes as ReactRoutes } from "react-router-dom";
import { AuthProvider, RequireAuth } from "react-auth-kit";
import { authenticationDisabled, refreshTokens } from "./services/auth/AuthService";
import Routes from "./components/routing/Routes";
import LandingPage from "views/LandingPage/LandingPage";
import Home from "views/Home/Home";
import Profile from "views/Profile/Profile";
import Environments from "./views/Registry/Environments/Environments";
import BackendImages from "./views/Registry/BackendImages/BackendImages";
import LoginCallback from "./views/LoginCallback/LoginCallback";
import Login from "./views/Login/Login";
import ClientBuilds from "./views/Registry/ClientBuilds/ClientBuilds";
import StaticData from "views/Registry/StaticData/StaticData";
import ServerImages from "./views/Registry/ServerImages/ServerImages";
import DataSchemaEditor from "./views/DataSchema/DataSchemaEditor";
import ManagedParameters from "./views/Registry/ManagedParameters/ManagedParameters";
import GameServers from "./views/GameServers/GameServers";
import DataStructureEditor from "views/DataSchema/DataStructureEditor";
import DataEntityEditor from "views/DataEntities/DataEntityEditor";
import LoadTesters from "./views/LoadTesters/LoadTesters";
import IntegrationTests from "./views/IntegrationTests/IntegrationTests";
import SwaggerDoc from "views/SwaggerDoc/SwaggerDoc";
import Players from "./views/Players/Players";
import GameServerActions from "./views/GameServerActions/GameServerActions";
import CwlLogViewer from "views/Logging/CloudWatch/CwlLogViewer";
import LogViewer from "views/Logging/LogViewer";
import FullBackup from "views/Backup/Full/FullBackup";
import PartialBackup from "views/Backup/Partial/PartialBackup";
import ArtAssets from "./views/ArtAssets/ArtAssets";
import ArtAssetsIdsGenerator from "./views/ArtAssets/ArtAssetsIdsGenerator";
import ArtAssetsIdsRawSheet from "./views/ArtAssets/ArtAssetsIdsRawSheet";

const Secure = (props: { target: ReactElement }) => {
  if (authenticationDisabled()) {
    return props.target;
  } else {
    return <RequireAuth loginPath={Routes.login}>{props.target}</RequireAuth>;
  }
};

const App = () => {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"}
      refresh={refreshTokens}
    >
      <ReactRoutes>
        <Route path={Routes.login} element={<Login />} />
        <Route path={Routes.loginCallback} element={<LoginCallback />} />
        <Route path={Routes.landing} element={<LandingPage />}>
          <Route path={Routes.home} element={<Secure target={<Home />} />} />
          <Route path={Routes.profile} element={<Secure target={<Profile />} />} />
          <Route path={Routes.environments} element={<Secure target={<Environments />} />} />
          <Route path={Routes.backendImages} element={<Secure target={<BackendImages />} />} />
          <Route path={Routes.clientBuilds} element={<Secure target={<ClientBuilds />} />} />
          <Route path={Routes.staticData} element={<Secure target={<StaticData />} />} />
          <Route path={Routes.serverImages} element={<Secure target={<ServerImages />} />} />
          <Route path={Routes.dataSchemaEditor} element={<Secure target={<DataSchemaEditor />} />}>
            <Route path={`${Routes.dataStructureEditor}`} element={<Secure target={<DataStructureEditor />} />} />
          </Route>
          <Route path={Routes.dataEntityEditor} element={<Secure target={<DataEntityEditor />} />} />
          <Route path={Routes.managedParameters} element={<Secure target={<ManagedParameters />} />} />
          <Route path={Routes.gameServers} element={<Secure target={<GameServers />} />} />
          <Route path={Routes.loadTesters} element={<Secure target={<LoadTesters />} />} />
          <Route path={Routes.logging} element={<Secure target={<LogViewer />} />} />
          <Route path={Routes.cwLogging} element={<Secure target={<CwlLogViewer />} />} />
          <Route path={Routes.integrationTests} element={<Secure target={<IntegrationTests />} />} />
          <Route path={Routes.swaggerDoc} element={<Secure target={<SwaggerDoc />} />} />
          <Route path={Routes.players} element={<Secure target={<Players />} />} />
          <Route path={Routes.gameServerActions} element={<Secure target={<GameServerActions />} />} />
          <Route path={Routes.backupFull} element={<Secure target={<FullBackup />} />} />
          <Route path={Routes.backupPartial} element={<Secure target={<PartialBackup />} />} />
          <Route path={Routes.artAssetsIdsGenerator} element={<Secure target={<ArtAssetsIdsGenerator />} />} />
          <Route path={Routes.artAssetsIdsBrowser} element={<Secure target={<ArtAssets />} />} />
          <Route path={Routes.artAssetsIdsRawSheet} element={<Secure target={<ArtAssetsIdsRawSheet />} />} />
        </Route>
      </ReactRoutes>
    </AuthProvider>
  );
};

const AppWithRouterAccess = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;
