import { AlertColor, Snackbar, Alert, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { IFullBackupManifest, useBackupService } from "services/backend/BackupService";
import { LoadingOverlay } from "components/LoadingOverlay";
import FullBackupTable from "views/Backup/Full/FullBackupTable";
import { AppContext } from "utils/AppContext";
import * as React from "react";

const FullBackups = () => {
  const backupService = useBackupService();
  const [backupList, setBackupList] = useState<IFullBackupManifest[]>([]);
  const [alertMessage, setAlertMessage] = useState<string | undefined>(undefined);
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");
  const [loading, setLoading] = useState(false);

  const alertCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertMessage(undefined);
  };

  const context = React.useContext(AppContext);
  useEffect(() => {
    context?.setTitle("Full Backups");
    (async () => fetchBackupList())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context]);

  const fetchBackupList = async () => {
    try {
      setLoading(true);
      const { manifestList } = await backupService.listFullBackups();
      setBackupList(manifestList);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      setAlertMessage(e.debugMessage || "Could not fetch the list of partial backups.");
      setAlertSeverity("error");
      setBackupList([]);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Box style={{ width: "100%" }}>
        <FullBackupTable rows={backupList}></FullBackupTable>
      </Box>
      <Snackbar open={alertMessage !== undefined} autoHideDuration={5000} onClose={alertCloseHandler}>
        <Alert onClose={alertCloseHandler} severity={alertSeverity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
      {loading && <LoadingOverlay />}
    </>
  );
};
export default FullBackups;
