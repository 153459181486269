import { Dialog, DialogContent, DialogActions, Button } from "@mui/material";
import { DiffMethod } from "react-diff-viewer-continued";
import ReactDiffViewer from "react-diff-viewer-continued";

export interface IDiffData {
  title: string;
  content: object;
}

interface JSONDiffProps {
  oldData: IDiffData;
  newData: IDiffData;
  visible: boolean;
  onClose: () => void;
}

export default function JSONDiff({ oldData, newData, visible, onClose }: JSONDiffProps) {
  return (
    <Dialog open={visible} onClose={onClose} PaperProps={{ sx: { width: "100%", maxWidth: "80vw!important" } }}>
      <DialogContent>
        {oldData && newData && (
          <ReactDiffViewer
            oldValue={oldData.content}
            newValue={newData.content}
            splitView={true}
            compareMethod={DiffMethod.JSON}
            leftTitle={oldData.title}
            rightTitle={newData.title}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="error" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
