import * as React from "react";
import { useState, useMemo, MouseEvent, ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { DateTime } from "luxon";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import Box from "@mui/material/Box";
import { visuallyHidden } from "@mui/utils";
import Toolbar from "@mui/material/Toolbar";
import { Button, Grid, Link, Menu, MenuItem, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import { MoreVert } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import { getComparator, Order, stableSort } from "../../../utils/Table";
import { IBackendImage } from "../../../services/registry/BackendImageService";

interface HeadCell {
  id: keyof IBackendImage;
  label: string;
  align?: "inherit" | "left" | "center" | "right" | "justify";
  padding?: "normal" | "checkbox" | "none";
}

const headCells: readonly HeadCell[] = [
  {
    id: "id",
    label: "ID",
    padding: "none",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "branch",
    label: "Branch",
  },
  {
    id: "creationDate",
    label: "Creation Date",
    align: "right",
  },
  {
    id: "commitHash",
    label: "Commit Hash",
    align: "right",
  },
];

interface BackendImagesTableHeadProps {
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IBackendImage) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const BackendImagesTableHead = ({ order, orderBy, onRequestSort }: BackendImagesTableHeadProps) => {
  const createSortHandler = (property: keyof IBackendImage) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="normal" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.padding}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell padding="normal" />
      </TableRow>
    </TableHead>
  );
};

export interface BackendImagesTableProps {
  rows: IBackendImage[];
  setSelectedItem: Dispatch<SetStateAction<IBackendImage | undefined>>;
  onAddButtonClick: () => void;
  onRemoveMenuClick: () => void;
  onShowDeploymentsMenuClick: () => void;
  initialFilterQuery: string | undefined;
}

export const BackendImagesTable = ({
  rows,
  setSelectedItem,
  onAddButtonClick,
  onRemoveMenuClick,
  onShowDeploymentsMenuClick,
  initialFilterQuery,
}: BackendImagesTableProps) => {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState<keyof IBackendImage>("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [menuAnchor, setMenuAnchor] = useState<Element | undefined>(undefined);
  const [filterQuery, setFilterQuery] = useState<string>("");

  useEffect(() => {
    if (initialFilterQuery) {
      setFilterQuery(initialFilterQuery);
    }
  }, [initialFilterQuery]);

  const requestSortHandler = (event: MouseEvent<unknown>, property: keyof IBackendImage) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const pageChangeHandler = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const rowsPerPageChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const menuOpenHandler = (event: MouseEvent<HTMLElement>, item: IBackendImage) => {
    setMenuAnchor(event.target as Element);
    setSelectedItem(item);
  };

  const menuCloseHandler = () => {
    setMenuAnchor(undefined);
  };

  const removeMenuClickHandler = () => {
    onRemoveMenuClick();
    menuCloseHandler();
  };

  const showDeploymentsMenuClickHandler = () => {
    onShowDeploymentsMenuClick();
    menuCloseHandler();
  };

  const filteredRows = useMemo(() => {
    const query = filterQuery.toLowerCase();
    return rows.filter((row) => {
      return (
        row.id.includes(query) ||
        row.name?.toLowerCase().includes(query) ||
        row.branch?.toLowerCase().includes(query) ||
        row.commitHash?.includes(query)
      );
    });
  }, [rows, filterQuery]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(() => {
    return stableSort(filteredRows, getComparator(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage,
    );
  }, [filteredRows, order, orderBy, page, rowsPerPage]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Toolbar sx={{ pl: { sm: 2 }, pr: { xs: 1, sm: 1 }, pt: { xs: 1, sm: 1 } }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Button variant="outlined" startIcon={<AddIcon />} onClick={onAddButtonClick} style={{ height: 40 }}>
                Add
              </Button>
              <TextField
                id="outlined-search"
                label="Search backend images"
                type="search"
                size="small"
                onChange={(e) => setFilterQuery(e.target.value)}
                sx={{ width: "360px", marginLeft: 1 }}
                value={filterQuery}
              />
            </Grid>
          </Grid>
        </Toolbar>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} size="small">
            <BackendImagesTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={requestSortHandler}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const labelId = `backend-images-table-checkbox-${index}`;
                const creationDate = DateTime.fromSeconds(row.creationDate).toLocaleString(
                  DateTime.DATETIME_SHORT_WITH_SECONDS,
                );
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id} sx={{ cursor: "pointer" }}>
                    <TableCell padding="normal" />
                    <TableCell component="th" id={labelId} style={{ fontFamily: "monospace" }} scope="row" padding="none">
                      {row.id}
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell align="left">{row.branch}</TableCell>
                    <TableCell align="right">{creationDate}</TableCell>
                    <TableCell align="right" style={{ fontFamily: "monospace" }}>
                      <Link
                        onClick={() =>
                          window.open(`https://github.com/FortisGames/proj-townsfolk-backend/commit/${row.commitHash}`)
                        }
                      >
                        {row.commitHash}
                      </Link>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={(e) => menuOpenHandler(e, row)}>
                        <MoreVert />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 33 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={pageChangeHandler}
          onRowsPerPageChange={rowsPerPageChangeHandler}
        />
        <Menu anchorEl={menuAnchor} keepMounted open={menuAnchor !== undefined} onClose={() => menuCloseHandler()}>
          <MenuItem onClick={() => removeMenuClickHandler()}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Remove</Typography>
          </MenuItem>
          <MenuItem onClick={() => showDeploymentsMenuClickHandler()}>
            <ListItemIcon>
              <FormatListBulletedIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Show Deployments</Typography>
          </MenuItem>
        </Menu>
      </Paper>
    </Box>
  );
};
