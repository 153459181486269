import { useAuthUser } from "react-auth-kit";
import { getRegistryURL } from "./Registry";
import { buildDeleteOptions, buildGetOptions, buildPostOptions, fetchWithErrorHandling } from "../FetchUtils";

const endpoint = (action: string): string => {
  return `${getRegistryURL()}/v1/registry/server-image/${action}`;
};

export interface IServerImage {
  id: string;
  name: string;
  creationDate: number;
  version: string;
  branch: string;
  environment: string | undefined;
  commitHash: string | undefined;
}

export const listServerImages = async (accessToken: string) => {
  return fetchWithErrorHandling(endpoint("list"), buildGetOptions(accessToken));
};

export const addServerImage = async (accessToken: string, name: string, version: string, branch: string) => {
  const options = buildPostOptions(accessToken, { name, version, branch });
  return fetchWithErrorHandling(endpoint("add"), options);
};

export const editServerImage = async (accessToken: string, id: string, name: string, version: string, branch: string) => {
  const options = buildPostOptions(accessToken, { id, name, version, branch });
  return fetchWithErrorHandling(endpoint("edit"), options);
};

export const removeServerImage = async (accessToken: string, id: string) => {
  return fetchWithErrorHandling(endpoint(`remove/${id}`), buildDeleteOptions(accessToken));
};

export const promoteServerImage = async (accessToken: string, serverImageId: string, environmentName: string) => {
  const options = buildPostOptions(accessToken, { serverImageId, environmentName });
  return fetchWithErrorHandling(endpoint("promote"), options);
};

export const useServerImageService = () => {
  const authUser = useAuthUser();
  const accessToken = authUser()?.access_token;

  return {
    listServerImages: () => listServerImages(accessToken),
    addServerImage: (name: string, version: string, branch: string) => addServerImage(accessToken, name, version, branch),
    editServerImage: (id: string, name: string, version: string, branch: string) =>
      editServerImage(accessToken, id, name, version, branch),
    removeServerImage: (id: string) => removeServerImage(accessToken, id),
    promoteServerImage: (serverImageId: string, environmentName: string) =>
      promoteServerImage(accessToken, serverImageId, environmentName),
  };
};
